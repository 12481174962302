import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {KullaniciSayfaAdet} from '../_models/kullanici-sayfa-adet.model';
import {SayfaAdetDropdown} from '../_models/sayfa-adet-dropdown.model';
import {MessageService} from 'primeng/api';
import {tap} from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class AppInitService {

    constructor(private http: HttpClient,
                private messageService: MessageService) {
    }

    static get getList(): KullaniciSayfaAdet[] {
        if (this.list == null) {
            return [];
        }
        return this.list;
    }

    public static list: KullaniciSayfaAdet[] = [];
    private API_URL = environment.API_URL;

    loadAppInitSayfaAdetData() {
        const token = localStorage.getItem('token');
        if (token != null && token !== '' && token.length > 10) {
            return this.http.get<any>(`${this.API_URL}/v1/Kullanicilar/SayfaAdet`).pipe(
                tap((resp) => {
                        AppInitService.list = this.getSayfaAdetParse(resp.data);
                        // console.log('APP___________INIT_SERVICE: ', AppInitService.list);
                    }
                )
            ).toPromise();
        }
    }

    getSayfaAdetParse(list: KullaniciSayfaAdet[]) {
        if (list != null && list.length > 0) {
            list.map((value, index, array) => {
                if (value == null || value.kod == null || value.kod.aciklama == null || value.kod.aciklama.length < 1) {
                    const kodAd = value != null && value.kod != null && value.kod.kodAd != null ? value.kod.kodAd : '';
                    this.messageService.add({
                        severity: 'info',
                        summary: 'Uyarı',
                        detail: kodAd + ' değerine ait sayfalama adet listesi girilmemişir. (10,25,50,100,250... vb) değerler olabilir.'
                    });
                } else {
                    try {
                        const json = JSON.parse(value.kod.aciklama);
                        value.model = new SayfaAdetDropdown();
                        value.model.varsayilan = json.varsayilan;
                        value.model.sabit = json.sabit;
                        value.model.dropdownList = json.dropdownList;
                        if (value.model.sabit) {
                            value.sayfaAdet = value.model.varsayilan;
                            value.model.dropdownIntArray = [value.sayfaAdet];
                        } else {
                            value.model.dropdownIntArray = value.model.dropdownList.map(m => m.adet);
                        }
                        if (!value.model.sabit && (value.id == null || value.id < 1)) {
                            //console.log(value);
                            value.sayfaAdet = value.model.varsayilan;
                        }
                    } catch (e) {
                        this.messageService.add({
                            severity: 'info',
                            summary: 'Uyarı',
                            detail: value.kod.kodAd + ' değerine ait json değeri hatalıdır. Değer parse edilemedi! Değer: ' + value.kod.aciklama
                        });
                    }
                }
                value.kodGuid = value.kod.guid;
            });
        } else {
            list = [];
        }
        return list;
    }
}
