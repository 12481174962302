import {Injectable} from '@angular/core';
import {
    Router,
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
} from '@angular/router';
import {AuthService} from './auth.service';

@Injectable({providedIn: 'root'})
export class AuthGuard implements CanActivate {
    constructor(private authService: AuthService) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const currentUser = this.authService.currentUserValue;
        if (currentUser != null && currentUser.tokenBilgisi != null && currentUser.tokenBilgisi.token != null) {
            // logged in so return true
            return true;
        }

        // not logged in so redirect to login page with the return url
        this.authService.logout();
        return false;
    }
}
