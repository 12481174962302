import {Injectable, Injector} from '@angular/core';
import {HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse, HttpErrorResponse} from '@angular/common/http';
import {Observable, of, throwError} from 'rxjs';
import {catchError, tap} from 'rxjs/operators';

import {Router} from '@angular/router';
import {MessageService} from 'primeng/api';


@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(
        private router: Router,
        private messageService: MessageService
        // private injector: Injector,
    ) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const token = localStorage.getItem('token');

        if (request && (
            request.url?.toLowerCase().indexOf('login') > -1
        )) {
        } else if (request) {
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ` + token}
            });
        }

        return next.handle(request).pipe(
            catchError((error) => {
                let handled = false;
                console.error(error);
                if (error instanceof HttpErrorResponse) {
                    if (error.error instanceof ErrorEvent) {
                        console.error('Error Event');
                    } else {
                        // console.log(`error status : ${error.status} ${error.statusText}`);
                        //  const messageService = this.injector.get(MessageService);
                        switch (error.status) {
                            case 401:      // login
                                localStorage.clear();
                                // console.log('AuthInterceptor 401 hatası aldı ve logine yönlendirdi...');
                                this.router.navigate(['/auth/login']);
                                handled = true;
                                this.messageService.add({
                                    severity: 'error',
                                    summary: 'Oturum açmadınız',
                                    detail: "Oturum süreniz doldu!"
                                });
                                break;
                            case 403:     // forbidden
                                // console.log('___________________________________________________________');
                                // console.log(error.error.Message);
                                this.messageService.add({
                                    severity: 'error',
                                    summary: error.error.Message,
                                    detail: request.method + ': ' + request.url
                                });
                                // console.log('___________________________________________________________');
                                handled = true;
                                break;
                        }
                    }
                } else {
                    console.error('Other Errors');
                }

                if (handled) {
                    // console.log('return back ');
                    return of(error);
                } else {
                    // console.log('throw error back to to the subscriber');
                    return throwError(error);
                }

            })
        );
    }


    /*return next.handle(request).pipe(
        tap((event: HttpEvent<any>) => {
            if (event instanceof HttpResponse) {
                // console.log('===========================================================');
                // console.log(event);
                // console.log(event.status);
                // console.log('===========================================================');

                if (event.status === 401 ) {
                    localStorage.clear();
                    // console.log('AuthInterceptor 401 hataı aldı ve logine yönlendirdi...');
                    this.router.navigate(['/auth/login']);
                }else if (event.status === 403 ) {
                    // console.log('___________________________________________________________');
                    // console.log(event.body.errors.Message);
                    // console.log('___________________________________________________________');
                }
            }
        }));
}*/
}
