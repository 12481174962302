import {Base} from './base.model';
import {Cinsiyet} from './cinsiyet.model';
import {Sunucu} from '../../_models/sunucu.model';
import {SunucuVeritabani} from '../../_models/SunucuVeritabani.model';
import {Pos} from '../../_models/pos.model';
import {Kasa} from '../../_models/kasa.model';
import {EUyeTip} from '../../../enums/e-uye-tip.enum';

export class Kullanici extends Base {
    public ad: string;
    public soyad: string;
    public kullaniciAdi: string;
    public sifre: string;
    public sifreTekrar: string;
    public eposta: string;
    public eposta2: string;
    public telefon: string;
    public telefon2: string;
    public telefon3: string;
    public adres: string;
    public adres2: string;
    public profilResmi: string;
    public aciklama: string;
    public deviceId: string;
    public deviceIdGerekli: boolean;

    public cinsiyetId: number;
    public sunucuGuid: string;
    public sunucuId: number;
    public kasa: Kasa = new Kasa();
    public kasaId: number;
    public kasaAdi: string;
    public bildirimGonderETicaret: boolean;
    public bildirimGonderETicaretIade: boolean;
    public sunucuVeritabaniId: number;
    public cinsiyet: Cinsiyet = new Cinsiyet();
    public sunucu: Sunucu = new Sunucu();
    public sunucuVeriTabani: SunucuVeritabani = new SunucuVeritabani();
    public posList: Pos[] = [];

    public uyeTipEnum: EUyeTip= EUyeTip.Normal;
    public yoneticiId: number;
    public yonetici: any;
}
