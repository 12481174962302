import {
    Component,
    ChangeDetectionStrategy,
    OnDestroy,
    OnInit, Injector,
} from '@angular/core';
import {TranslationService} from './modules/i18n/translation.service';
import {TranslateService} from '@ngx-translate/core';
// language list
import {locale as enLang} from './modules/i18n/vocabs/en';
import {locale as chLang} from './modules/i18n/vocabs/ch';
import {locale as esLang} from './modules/i18n/vocabs/es';
import {locale as jpLang} from './modules/i18n/vocabs/jp';
import {locale as deLang} from './modules/i18n/vocabs/de';
import {locale as frLang} from './modules/i18n/vocabs/fr';
import {locale as trLang} from './modules/i18n/vocabs/tr';
import {SplashScreenService} from './_metronic/partials/layout/splash-screen/splash-screen.service';
import {Router, NavigationEnd} from '@angular/router';
import {Subscription} from 'rxjs';
import * as moment from 'moment';
import {PrimeNGConfig} from 'primeng/api';

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'body[root]',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit, OnDestroy {
    private unsubscribe: Subscription[] = []; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/

    constructor(
        private translationService: TranslationService,
        private translateService: TranslateService,
        private splashScreenService: SplashScreenService,
        private router: Router,
        injector: Injector,
        private config: PrimeNGConfig
    ) {
        // super(injector);
        this.overrideDate();
        // register translations
        this.translationService.loadTranslations(
            trLang,
            enLang,
            chLang,
            esLang,
            jpLang,
            deLang,
            frLang
        );

        translateService.addLangs(['tr']);
        translateService.setDefaultLang('tr');
        translateService.use('tr');
        this.translate('tr');
    }
    translate(lang: string) {
        // console.log('AppComponent --- translate =====>', lang);
        this.translateService.use(lang);
        this.translateService.get('primeng').subscribe(res => this.config.setTranslation(res));
    }
    ngOnInit() {
        this.translateService.setDefaultLang('tr');
        const routerSubscription = this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                // hide splash screen
                this.splashScreenService.hide();

                // scroll to top on every route change
                window.scrollTo(0, 0);

                // to display back the body content
                setTimeout(() => {
                    document.body.classList.add('page-loaded');
                }, 500);
            }
        });
        this.unsubscribe.push(routerSubscription);
    }

    ngOnDestroy() {
        this.unsubscribe.forEach((sb) => sb.unsubscribe());
    }

    overrideDate() {
        Date.prototype.toJSON = function(key) {
            return moment(this).format('YYYY-MM-DDTHH:mm:ss');
            //This code return me as string like "25.02.0219 19:48:52"
            // return this.toLocaleDateString() + ' ' + this.toLocaleTimeString();
        };
    }
}
