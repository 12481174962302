import {Injectable} from '@angular/core';
import {FileSaverService} from 'ngx-filesaver';
import {MessageService} from 'primeng/api';
import {Dropdown} from '../modules/_models/dropdown.model';
declare var require: any;
import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from "pdfmake/build/vfs_fonts";
const htmlToPdfmake = require("html-to-pdfmake");
(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;

@Injectable()
export class GlobalService {
    format = 'yyyy-MM-dd';
    locale = 'en-US';

    public constructor(
        private _fileSaverService: FileSaverService,
        private messageService: MessageService
    ) {
    }

    public saveAsExcelFile(buffer: any, fileName: string): void {
        import('file-saver').then(() => {
            const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
            const EXCEL_EXTENSION = '.xlsx';
            const data: Blob = new Blob([buffer], {
                type: EXCEL_TYPE
            });
            this._fileSaverService.save(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
        });
    }

    public excelExport(list: any[]) {
        import('xlsx').then(xlsx => {
            const worksheet = xlsx.utils.json_to_sheet(list);
            const workbook = {Sheets: {data: worksheet}, SheetNames: ['data']};
            const excelBuffer: any = xlsx.write(workbook, {bookType: 'xlsx', type: 'array'});
            this.saveAsExcelFile(excelBuffer, 'liste');
        });
    }
    public pdfExport(html:any, fileName:string){
        var htmlData = html;
        var html = htmlToPdfmake(htmlData);
        var dd = {content:html};
        pdfMake.createPdf(dd).download();
    }
    public getParams(coStr: string) {
        try {
            const coJson = JSON.parse(coStr);
            // console.log('getParams', coJson);
            let params = <any> {};
            Object.keys(coJson).map(k => {
                // console.log(typeof coJson[k]);
                if (coJson[k] != null) {
                    params[k] = coJson[k];
                }
            });
            return params;
        } catch (e) {
            this.messageService.add({
                severity: 'error',
                summary: 'Hata oluştu',
                detail: 'İstek gönderilmeden önce bir hata oluştu! Hata: ' + e
            });
        }

    }

    public isDate(dateValue): boolean {
        return dateValue && Object.prototype.toString.call(dateValue) === '[object Date]' && !isNaN(dateValue);
    }

    // aynı olan key field'ına göre ilk değerini alır
    public getDistictList(arrObjectList: any[], key: string): any[] {
        return arrObjectList.reduce(
            (accumulator, current) => {
                if (!accumulator.some(x => x[key] === current[key])) {
                    accumulator.push(current);
                }
                return accumulator;
            }, []
        );
    }

    //aynı olan key field'ına göre son değerini alır
    public getDistictListByLastValue(arrObjectList: any[], key: string): any[] {
        return [...new Map(arrObjectList.map(item => [item[key], item])).values()];
    }

    public get getAylist(): Dropdown[] {
        const ayList: Dropdown[] = [];
        ayList.push({value: '01', name: '01-Ocak'});
        ayList.push({value: '02', name: '02-Şubat'});
        ayList.push({value: '03', name: '03-Mart'});
        ayList.push({value: '04', name: '04-Nisan'});
        ayList.push({value: '05', name: '05-Mayıs'});
        ayList.push({value: '06', name: '06-Haziran'});
        ayList.push({value: '07', name: '07-Temmuz'});
        ayList.push({value: '08', name: '08-Ağustos'});
        ayList.push({value: '09', name: '09-Eylül'});
        ayList.push({value: '10', name: '10-Ekim'});
        ayList.push({value: '11', name: '11-Kasım'});
        ayList.push({value: '12', name: '12-Aralık'});
        return ayList;
    }

    public getDurumlist(tumuGelsin: boolean): Dropdown[] {
        const durumList: Dropdown[] = [];
        if (tumuGelsin) {
            durumList.push({value: null, name: 'Tümü'});
        }
        durumList.push({value: true, name: 'Aktif'});
        durumList.push({value: false, name: 'Pasif'});
        return durumList;
    }
    public getIslemDurumlist(tumuGelsin: boolean): Dropdown[] {
        const durumList: Dropdown[] = [];
        if (tumuGelsin) {
            durumList.push({value: null, name: 'Tümü'});
        }
        durumList.push({value: 0, name: 'Silinenler'});
        durumList.push({value: 1, name: 'Eklenenler'});
        durumList.push({value: 2, name: 'Güncellenenler'});
        return durumList;
    }
    public getUyeTipList(tumuGelsin: boolean): Dropdown[] {
        const list: Dropdown[] = [];
        if (tumuGelsin) {
            list.push({value: null, name: 'Tümü'});
        }
        list.push(new Dropdown(0,"Normal"));
        list.push(new Dropdown(1,"Kurye"));
        return list;
    }
    public getSoruList(tumuGelsin: boolean): Dropdown[] {
        const list: Dropdown[] = [];
        if (tumuGelsin) {
            list.push({value: null, name: 'Tümü'});
        }
        list.push(new Dropdown(1,"1"));
        list.push(new Dropdown(2,"2"));
        list.push(new Dropdown(3,"3"));
        list.push(new Dropdown(4,"4"));
        list.push(new Dropdown(5,"5"));
        list.push(new Dropdown("Diğer","Diğer"));
        list.push(new Dropdown("Evet","Evet"));
        list.push(new Dropdown("Hayır","Hayır"));
        return list;
    }
    public getSonrakiYilList(sonrakiYil: number): Dropdown[] {
        const yilList: Dropdown[] = [];
        const tarih = new Date();
        const simdikiYil = tarih.getFullYear();
        for (let yil = simdikiYil; yil <= (simdikiYil + sonrakiYil); yil++) {
            yilList.push({value: yil, name: yil.toString()});
        }
        return yilList;
    }
    public getYilList(baslamaYili: number, sonrakiYil: number): Dropdown[] {
        const yilList: Dropdown[] = [];
        const tarih = new Date();
        const simdikiYil = tarih.getFullYear();
        for (let yil = baslamaYili; yil <= (simdikiYil + sonrakiYil); yil++) {
            yilList.push({value: yil, name: yil.toString()});
        }
        return yilList;
    }
    public replaceAll(str: string, find: string, replace: string): string {
        return str.replace(new RegExp(find, 'g'), replace);
    }
    public getCardNo(cardNo: string): string | undefined {
        if (cardNo == null) {
            return;
        }
        let numberCardNo = this.replaceAll(cardNo, ' ', '');
        numberCardNo = this.replaceAll(numberCardNo, '\\*', '');
        numberCardNo = this.replaceAll(numberCardNo, '_', '');

        return numberCardNo;
    }
    public getCardKey(cardNo: string): string | undefined {
        if (cardNo == null) {
            return;
        }
        let numberCardNo = this.replaceAll(cardNo, ' ', '');
        numberCardNo = this.replaceAll(numberCardNo, '\\*', '');
        numberCardNo = this.replaceAll(numberCardNo, '_', '');

        console.log({cardNo});
        console.log({numberCardNo});


        var re = {
            electron: /^(4026|417500|4405|4508|4844|4913|4917)\d+$/,
            maestro: /^(5018|5020|5038|5612|5893|6304|6759|6761|6762|6763|0604|6390)\d+$/,
            dankort: /^(5019)\d+$/,
            interpayment: /^(636)\d+$/,
            unionpay: /^(62|88)\d+$/,
            visa: /^4[0-9]{12}(?:[0-9]{3})?$/,
            mastercard: /^5[1-5][0-9]{14}$/,
            amex: /^3[47][0-9]{13}$/,
            diners: /^3(?:0[0-5]|[68][0-9])[0-9]{11}$/,
            discover: /^6(?:011|5[0-9]{2})[0-9]{12}$/,
            jcb: /^(?:2131|1800|35\d{3})\d{11}$/
        };

        for (var key in re) {
            if (re[key].test(numberCardNo)) {
                return key;
            }
        }

    }
}
