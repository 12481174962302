// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  appVersion: 'v713veribel',
  USERDATA_KEY: 'authf649fc9a5f550061',
  isMockEnabled: false,
  apiUrl: 'api',
  //API_URL: 'https://nextapi.zirhlioglugida.com.tr/api',
  API_URL: 'https://testapi.zirhlioglugida.com.tr/api',

  // API_URL: 'https://nextapi.zirhlioglugida.com.tr/api',
  TAHSILAT_URL: 'http://nextpayment.veribel.com.tr/api',
  // API_URL: 'http://192.100.3.19:60581/api',
 // API_URL: 'http://localhost:60581/api',
  calendar: {
    firstDayOfWeek: 1,
    dayNames: ['Pazar', 'Pazartesi', 'Salı', 'Çarşamba', 'Perşembe', 'Cuma', 'Cumartesi'],
    dayNamesShort: ['Paz', 'Pts', 'Sal', 'Çar', 'Per', 'Cum', 'Cts'],
    dayNamesMin: ['Pa', 'Pt', 'Sa', 'Ça', 'Pe', 'Cu', 'Ct'],
    monthNames: ['Ocak', 'Şubat', 'Mart', 'Nisan', 'Mayıs', 'Haziran', 'Temmuz', 'Ağustos', 'Eylül', 'Ekim', 'Kasım', 'Aralık'],
    monthNamesShort: ['Ock', 'Şub', 'Mar', 'Nis', 'May', 'Haz', 'Tem', 'Ağu', 'Eyl', 'Ekm', 'Kas', 'Ara'],
    today: 'Bugün',
    clear: 'Temizle'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
