import {Base} from '../auth/_models/base.model';
import {Sunucu} from './sunucu.model';

export class SunucuVeritabani extends Base {
    public sunucuId: number;
    public adi: string;
    public kullaniciAdi: string;
    public sifre: string;
    public aciklama: string;
    public sunucu: Sunucu = new Sunucu();
}
