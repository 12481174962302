import {Injectable, OnDestroy} from '@angular/core';
import {Observable, BehaviorSubject, of, Subscription} from 'rxjs';
import {map, catchError, switchMap, finalize, tap} from 'rxjs/operators';
import {UserModel} from '../_models/user.model';
import {environment} from 'src/environments/environment';
import {Router} from '@angular/router';
import {AuthHTTPService} from './auth-http/auth-http.service';
import {GirisModel} from '../_models/giris.model';
import {AppInitService} from '../../_services/app-init.service';

@Injectable({
    providedIn: 'root',
})
export class AuthService implements OnDestroy {
    // private fields
    private unsubscribe: Subscription[] = []; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/
    private authLocalStorageToken = `${environment.appVersion}-${environment.USERDATA_KEY}`;

    // public fields
    currentUser$: Observable<GirisModel>;
    isLoading$: Observable<boolean>;
    errorMessage: any;
    currentUserSubject: BehaviorSubject<GirisModel>;
    isLoadingSubject: BehaviorSubject<boolean>;


    get currentUserValue(): GirisModel {
        return this.currentUserSubject.value;
    }

    set currentUserValue(user: GirisModel) {
        this.currentUserSubject.next(user);
    }

    constructor(
        private authHttpService: AuthHTTPService,
        private appInitService: AppInitService,
        private router: Router
    ) {
        this.isLoadingSubject = new BehaviorSubject<boolean>(false);
        this.currentUserSubject = new BehaviorSubject<GirisModel>(new GirisModel());
        this.currentUser$ = this.currentUserSubject.asObservable();
        this.isLoading$ = this.isLoadingSubject.asObservable();
        const subscr = this.getUserByToken().subscribe();
        this.unsubscribe.push(subscr);
    }

    // public methods
    login(kullaniciAdi: string, sifre: string): Observable<GirisModel> {
        this.isLoadingSubject.next(true);
        return this.authHttpService.login(kullaniciAdi, sifre).pipe(
            map((auth) => {
                // // console.log('AUTH ------>', auth);
                if (auth.success) {
                    const data = auth.data as GirisModel;
                    // this.currentUserSubject = auth.data;
                    this.currentUser$ = auth.data;
                    this.currentUserSubject = new BehaviorSubject<GirisModel>(data);
                    this.setAuthFromLocalStorage(auth.data);
                } else {
                    this.errorMessage = auth.message;
                    return of(auth)
                        .pipe(finalize(() =>  {}));
                }
                return auth;
            }),
            switchMap(() => this.getUserByToken()),
            catchError((err) => {
                console.error('Auth.Service: login(): =======================================:hata::', err);
                return of(err);
            }),
            finalize(() => this.isLoadingSubject.next(false))
        );
    }

    logout() {
        localStorage.removeItem(this.authLocalStorageToken);
        localStorage.removeItem('token');
        this.router.navigate(['/auth/login'], {
            queryParams: {},
        });
    }

    getUserByToken(): Observable<GirisModel> {
        const auth = this.getAuthFromLocalStorage();
        if (!auth || !auth.tokenBilgisi?.token) {
            return of(this.errorMessage);
        }

        this.isLoadingSubject.next(true);
        this.currentUserSubject = new BehaviorSubject<GirisModel>(auth);
        return of(auth)
            .pipe(finalize(() => {}));

    }

    // need create new user then login
    registration(user: UserModel): Observable<any> {
        this.isLoadingSubject.next(true);
        return this.authHttpService.createUser(user).pipe(
            map(() => {
                this.isLoadingSubject.next(false);
            }),
            switchMap(() => this.login(user.email, user.password)),
            catchError((err) => {
                console.error('err', err);
                return of(undefined);
            }),
            finalize(() => this.isLoadingSubject.next(false))
        );
    }

    forgotPassword(email: string): Observable<boolean> {
        this.isLoadingSubject.next(true);
        return this.authHttpService
            .forgotPassword(email)
            .pipe(finalize(() => this.isLoadingSubject.next(false)));
    }

    // private methods
    private setAuthFromLocalStorage(girisModel: GirisModel): boolean {
       // // console.log('setAuthFromLocalStorage   ?========================>>>', girisModel);
        // store auth accessToken/refreshToken/epiresIn in local storage to keep user logged in between page refreshes
        if (girisModel && girisModel.tokenBilgisi && girisModel.tokenBilgisi.token) {
           // // console.log('localStorage   ?========================>>>', girisModel);
            localStorage.setItem(this.authLocalStorageToken, JSON.stringify(girisModel));
            localStorage.setItem('token', girisModel.tokenBilgisi.token);
            this.appInitService.loadAppInitSayfaAdetData();
            return true;
        }
        return false;
    }

    private getAuthFromLocalStorage(): GirisModel | undefined {
        try {
            const authData = JSON.parse(
                <string> localStorage.getItem(this.authLocalStorageToken)
            );
            return authData;
        } catch (error) {
            console.error(error);
            return undefined;
        }
    }

    ngOnDestroy() {
        this.unsubscribe.forEach((sb) => sb.unsubscribe());
    }
}
