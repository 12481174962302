export class Base {
    public id: number;
    public guid: string;
    public aktif: boolean = true;
    public silindi: string;
    public subeAdi: string;
    public eklenmeZamani: any;
    public guncellenmeZamani: any;
    public silinmeZamani: any;

    public kuryeAktif: boolean;
    public aracAktif: boolean;
    public sayfaNo: number;
    public sayfaAdet: number;
}
