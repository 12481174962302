import {NgModule, APP_INITIALIZER} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {ClipboardModule} from 'ngx-clipboard';
import {InlineSVGModule} from 'ng-inline-svg';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {AuthService} from './modules/auth/_services/auth.service';
import {NgHttpLoaderModule} from 'ng-http-loader'; // <============

// Highlight JS
// import { HighlightModule, HIGHLIGHT_OPTIONS } from 'ngx-highlightjs';
// import xml from 'highlight.js/lib/languages/xml';
// import json from 'highlight.js/lib/languages/json';
// import scss from 'highlight.js/lib/languages/scss';
// import typescript from 'highlight.js/lib/languages/typescript';
import {SplashScreenModule} from './_metronic/partials/layout/splash-screen/splash-screen.module';
import {AuthInterceptor} from './interceptor/auth.interceptor';
import {MessageService} from 'primeng/api';
import {ToastModule} from 'primeng/toast';
import {AppInitService} from './modules/_services/app-init.service';
import {FileSaverModule} from 'ngx-filesaver';
import {GlobalService} from './common/global.service';
import {BaseService} from './modules/_services/base.service';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}

function appInitializer(authService: AuthService) {
    return () => {
        return new Promise(async (resolve) => {
            authService.getUserByToken().subscribe().add(resolve);
        });
    };
}

/**
 * Import specific languages to avoid importing everything
 * The following will lazy load highlight.js core script (~9.6KB) + the selected languages bundle (each lang. ~1kb)
 */
// export function getHighlightLanguages() {
//   return [
//     { name: 'typescript', func: typescript },
//     { name: 'scss', func: scss },
//     { name: 'xml', func: xml },
//     { name: 'json', func: json },
//   ];
// }

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        SplashScreenModule,
        // TranslateModule.forRoot(),
        TranslateModule.forRoot({
            defaultLanguage: 'tr',
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        HttpClientModule,
        // HighlightModule,
        ClipboardModule,
        AppRoutingModule,
        InlineSVGModule.forRoot(),
        NgbModule,
        ToastModule,
        FileSaverModule,
        NgHttpLoaderModule.forRoot(),
    ],
    providers: [
        // {provide: BaseService, useExisting: BaseService}, // For those components which need functionality from base class only
        // {provide: BaseService, useExisting: GlobalService}, // For those components which need functionality from base class only
        // {provide: BaseService, useExisting: GlobalService}, // For those components which need functionality from base class only
        MessageService,
        GlobalService,
        // {provide: LocationStrategy, useClass: HashLocationStrategy},
        {
            provide: APP_INITIALIZER,
            useFactory: appInitializer,
            multi: true,
            deps: [AuthService],
        },
        {
            provide: APP_INITIALIZER,
            useFactory: (configService: AppInitService) =>
                () => configService.loadAppInitSayfaAdetData(),
            deps: [AppInitService],
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true
        },
        // {
        //   provide: HIGHLIGHT_OPTIONS,
        //   useValue: {
        //     languages: getHighlightLanguages,
        //   },
        // }
    ],
    bootstrap: [AppComponent],
})
export class AppModule {
}
